import { Flex, Radio, RadioGroup, Text, Button } from "@chakra-ui/react";
import { Fragment, useState } from "react";
import { FormattedMessage } from "react-intl";
import Icon from "./Icon";

const MFASelectionButtons = <AuthenticationMethod,>({
  authMethods,
  renderAuthForm,
}: {
  authMethods: Array<AuthenticationMethod>;
  renderAuthForm: (authMethod: AuthenticationMethod) => React.ReactNode;
}) => {
  const [authMethod, handleAuthMethod] = useState<AuthenticationMethod>(
    authMethods[0],
  );

  const companyEmailString = "COMPANY_EMAIL" as keyof AuthenticationMethod;
  const smsString = "SMS" as keyof AuthenticationMethod;
  const passwordListString = "PASSWORD_LIST" as keyof AuthenticationMethod;

  return (
    <Flex
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      gap="4"
      width="100%"
      pt="4"
    >
      <Text fontWeight="bold">
        <FormattedMessage
          id="login.selectAuthenticationMethod"
          defaultMessage="Select authentication method"
        />
      </Text>

      <Flex flexDirection="row" width="100%">
        <RadioGroup
          width="100%"
          justifyContent="center"
          value={authMethod as keyof AuthenticationMethod as string}
          onChange={(option) => {
            handleAuthMethod(option as AuthenticationMethod);
          }}
          gap="2"
          display="flex"
          flexDirection={["column", "column", "column"]}
        >
          {authMethods.map((method) => (
            <Fragment key={method as string}>
              {method === smsString && (
                <Button
                  //   maxWidth={["100%", "100%", "240px"]}
                  width="100%"
                  padding="0"
                  variant="ghost"
                  size="lg"
                  border={
                    authMethod === smsString ? "2px solid blue" : undefined
                  }
                >
                  <Radio
                    value={smsString as string}
                    width="100%"
                    height="100%"
                    padding="4"
                  >
                    <Flex alignItems="center" gap="2">
                      <Icon name="mobilePhone" />
                      <FormattedMessage
                        id="login.authMethods.smsCode"
                        defaultMessage="SMS"
                      />
                    </Flex>
                  </Radio>
                </Button>
              )}
              {method === passwordListString && (
                <Button
                  width="100%"
                  padding="0"
                  variant="ghost"
                  size="lg"
                  border={
                    authMethod === passwordListString
                      ? "2px solid blue"
                      : undefined
                  }
                >
                  <Radio
                    padding="4"
                    value={passwordListString as string}
                    width="100%"
                    height="100%"
                  >
                    <Flex alignItems="center" gap="2">
                      <Icon name="passwordList" />
                      <FormattedMessage
                        id="login.authMethods.passwordList"
                        defaultMessage="Password list"
                      />
                    </Flex>
                  </Radio>
                </Button>
              )}

              {method === companyEmailString && (
                <Button
                  width="100%"
                  padding="0"
                  variant="ghost"
                  size="lg"
                  border={
                    authMethod === companyEmailString
                      ? "2px solid blue"
                      : undefined
                  }
                >
                  <Radio
                    padding="4"
                    value={companyEmailString as string}
                    width="100%"
                    height="100%"
                  >
                    <Flex alignItems="center" gap="2">
                      <Icon name="mail" />
                      <FormattedMessage
                        id="login.authMethods.companyEmail"
                        defaultMessage="Company email"
                      />
                    </Flex>
                  </Radio>
                </Button>
              )}
            </Fragment>
          ))}
        </RadioGroup>
      </Flex>

      {renderAuthForm && renderAuthForm(authMethod)}
    </Flex>
  );
};

export default MFASelectionButtons;
