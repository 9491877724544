import { Fragment, useState } from "react";
import {
  SendSmsVerificationCodeMutation,
  VerifyMfaCodeMutation,
  VerifyMfaCodeMutationVariables,
} from "apollo/generatedTypes";
import { Alert, Button, Divider, Flex, Input, Text } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { gql, useMutation } from "@apollo/client";
import getApolloClient from "apollo/client";
import { QueryState, getQueryState } from "components/queryUtil";
import Icon from "@eam/incentive-ui/components/Icon";
import { forbiddedErrorTypeGuard } from "./forbiddenErrorTypeGuard";

const SEND_SMS_CODE = gql`
  mutation sendSMSVerificationCode {
    sendSMSVerificationCode
  }
`;

const VERIFY_MFA_CODE = gql`
  mutation verifyMFACode($password: String!) {
    verifyMFACode(password: $password) {
      baseUrl
      id
    }
  }
`;

const apolloClient = getApolloClient();

const SMSMFAForm = ({
  handleLogin,
}: {
  handleLogin: (baseUrl: string) => void;
}) => {
  const [MFAPassword, handleMFAPassword] = useState<string>("");

  const [sendSMSCode, sendSMSPayload] =
    useMutation<SendSmsVerificationCodeMutation>(SEND_SMS_CODE, {
      client: apolloClient,
      onError() {
        //
      },
    });

  const smsQueryState = getQueryState(sendSMSPayload);

  const [verifyCode, verifyPayload] = useMutation<
    VerifyMfaCodeMutation,
    VerifyMfaCodeMutationVariables
  >(VERIFY_MFA_CODE, {
    client: apolloClient,
    onCompleted({ verifyMFACode }) {
      if (verifyMFACode) {
        const { baseUrl } = verifyMFACode ?? {};
        handleLogin(baseUrl as string);
      }
    },
    onError() {
      //
    },
  });

  const verifyQueryState = getQueryState(verifyPayload);

  return (
    <Flex flexDirection="column" py="4" width="100%" gap="4">
      <Divider />
      <Flex alignItems="center" flexDirection="column" width="100%" gap="4">
        <Button
          width="100%"
          variant="outlineBlue"
          onClick={async () => {
            await sendSMSCode();
          }}
          isLoading={smsQueryState === QueryState.IN_PROGRESS}
          leftIcon={<Icon name="mobilePhone" />}
        >
          <FormattedMessage
            id="login.authenticateWithSMS"
            defaultMessage="Authenticate with SMS"
          />
        </Button>

        {smsQueryState === QueryState.FINISHED && (
          <Text fontSize="sm">
            <FormattedMessage
              id="login.MFACodeSent"
              defaultMessage="Code sent!"
            />
          </Text>
        )}
        {smsQueryState === QueryState.ERRORED && (
          <Text fontSize="sm">
            <FormattedMessage
              id="login.MFASMSCodeSentError"
              defaultMessage="An error occurred while sending the Email code"
            />
          </Text>
        )}
      </Flex>

      {forbiddedErrorTypeGuard(sendSMSPayload?.error) && (
        <Fragment>
          <Alert status="error">
            <Text fontSize="sm">
              <FormattedMessage
                id="login.MFATemporaryTokenExpired"
                defaultMessage="Your session has timed out, please login again."
              />
            </Text>
          </Alert>
        </Fragment>
      )}

      {smsQueryState === QueryState.FINISHED && (
        <Flex alignItems="center" gap="2">
          <Input
            isRequired={true}
            type="text"
            placeholder="Enter code from SMS"
            onChange={(e) => {
              handleMFAPassword(e.target.value);
            }}
            value={MFAPassword}
          />

          <Button
            isDisabled={!MFAPassword}
            isLoading={verifyQueryState === QueryState.IN_PROGRESS}
            variant="solidBlue"
            onClick={() => {
              verifyCode({
                variables: {
                  password: MFAPassword,
                },
              });
            }}
          >
            <FormattedMessage id="login.login" defaultMessage="Log in" />
          </Button>
        </Flex>
      )}

      {verifyQueryState === QueryState.ERRORED && (
        <Alert status="error" flexDirection="column">
          <Text fontSize="sm">
            <FormattedMessage
              id="login.MFAAuthFailed"
              defaultMessage="Authentication failed"
            />
          </Text>

          {verifyPayload.error?.message && (
            <Text fontSize="sm">{verifyPayload.error.message}</Text>
          )}
        </Alert>
      )}
    </Flex>
  );
};

export default SMSMFAForm;
