import { ForbiddenError } from "apollo-server-errors";

const forbiddedErrorTypeGuard = (
  error: any
): error is ForbiddenError & {
  cause: { extensions: { code: string } };
} => {
  return error?.cause?.extensions?.code === "FORBIDDEN";
};

export { forbiddedErrorTypeGuard };
