import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation login($username: String!, $password: String!, $ip: String) {
    login(username: $username, password: $password, ip: $ip) {
      ... on Company {
        baseUrl
        id
      }
      ... on LoginTwoFactorAuthRequiredResponse {
        methods
      }
    }
  }
`;
